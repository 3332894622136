@import '../mixins/utilities.scss';


.intro-ax-container {
  display: grid;
  width: 100%;
  grid-template-columns: 5fr 5fr;
  margin-top: 60px;
  grid-column-gap: 30px;

  @media screen and (max-width: 1024px) {
    @include flex-column;
  }

  .text-info-wrapper {
    @include flex-column;

    .intro-header {
      font-size: 20px;
      @include font-medium;
      letter-spacing: 1px;
      text-transform: uppercase;
    }

    .intro-second-header {
      font-size: 85px;
      line-height: 80px;
      letter-spacing: -2px;
      @include font-bold;

      @media screen and (max-width: 1440px) {
        font-size: 75px;
      }
    }

    .intro-third-header {
      font-size: 55px;
      line-height: 53px;
      letter-spacing: -1px;
      @include font-bold;

      @media screen and (max-width: 1536px) {
        font-size: 45px;
        line-height: 43px;
      }
    }

    .intro-ml-text {
      font-size: 30px;
      line-height: 30px;
      margin-top: 10px;
      @include font-medium;
      text-underline-offset: 5px;

      @media screen and (max-width: 1536px) {
        font-size: 27px;
        line-height: 30px;
      }

      u {
        cursor: pointer;
      }
    }

    .note-text {
      margin-top: 30px;
      @include font-x-light;
      font-size: 17px;
    }

    .intro-link {
      @include router_link;
      width: fit-content;
      margin-top: 40px;

      .arrow-container {
        height: 45px;
        padding-left: 15px;
        padding-right: 15px;
      }
    }
  }

  .gift-state-wrapper {
    @include flex-column;
    margin-top: 60px;
    align-items: center;

    > :not(:first-child) {
      margin-top: 35px;
    }

    .gift-error {
      font-size: 20px;
      text-align: start;
      @include font-medium;
    }
  }
}

.gift-item-container {
  display: grid;
  grid-template-columns: 70px auto;
  grid-column-gap: 15px;

  @media screen and (max-width: 640px) {
    @include flex-column;
  }

  .gift-state-number {
    @include font-medium;
    @include flex-column-center;
    font-size: 23px;

    @media screen and (max-width: 640px) {
      align-items: flex-start;
    }
  }

  .gift-item-wrapper {
    //display: grid;
    //grid-template-columns: 40px auto;
    @include flex-row;
    height: 50px;
    width: 370px;

    @media screen and (max-width: 420px) {
      width: 310px;
    }

    svg {
      width: auto;
      flex-basis: 58px;
      height: auto;
    }

    span {
      @include border-b(3px);
      @include flex-column-center;
      text-transform: uppercase;
      letter-spacing: 1px;
      @include font-medium;
      width: 100%;
      user-select: none;
    }

    .type-1 {
      cursor: pointer;
    }

    .type-2 {
      cursor: pointer;
    }

    .type-ACTIVE {
      border-left: none;
      cursor: unset;
    }

    .type-CURRENT {
      background-color: $color-black;
      color: $color-white;
    }

    .type-LOCK {
      background-color: $color-gray-mid;
      color: $color-text-secondary;
      border-color: transparent;
      cursor: unset;
    }

    .link-join-the-party-mal {
      width: 100%;
      display: flex;
      flex-direction: column-reverse;
      align-items: center;
      justify-content: center;
      @include router_link;
    }
  }
}
