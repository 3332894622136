@import '../mixins/utilities.scss';


.series-ax-container {
  margin-top: 50px;
  @include flex-column;
  padding-bottom: 30px;

  .header-one {
    font-size: 20px;
    letter-spacing: 1px;
    @include font-medium;
  }

  .header-two {
    @include font-bold;
    font-size: 40px;
    line-height: 43px;
  }

  .until {
    font-size: 25px;
    @include font-bold;
  }

  .series-ax-grid-wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    margin-top: 20px;
    grid-column-gap: 20px;
    grid-row-gap: 35px;


    @media screen and (max-width: 992px) {
      grid-template-columns: 1fr 1fr 1fr;
    }


    @media screen and (max-width: 768px) {
      grid-template-columns: 1fr 1fr;
    }

    @media screen and (max-width: 420px) {
      grid-template-columns: 1fr 1fr;
    }
  }
}

.series-item-wrapper {
  @include flex-column;
  background-color: white;

  .series-item-link {
    @include router_link;
    @include flex-column;
    width: 100%;
    height: auto;
  }

  .series-item-info-wrapper {
    width: 90%;
    @include flex-column;
    padding-bottom: 15px;
    margin: 10px auto;

    a {
      @include router_link;
      margin-top: 20px;
      align-self: flex-start;
    }
  }

  .series-name {
    font-size: 24px;
    @include font-medium;
    margin-top: 10px;
    margin-bottom: 0;
    white-space: break-spaces;
  }

  .sx-author-name {
    color: $color-text-secondary;
    margin-top: 0;
  }

  .ui-underlined-text {
    margin-top: 20px;
    align-self: flex-start;
    font-size: 14px;
    border-bottom-color: black;
    line-height: 15px;
  }

  .genres-wrapper {
    @include flex-row;
    flex-wrap: wrap;
    column-gap: 5px;
    line-height: 15px;
    margin-top: 10px;

    //> :not(:first-child) {
    //  margin-left: 5px;
    //}

    span {
      color: $color-text-secondary;
      @include font-medium;
      font-size: 15px;
      display: flex;
      //white-space: nowrap;
      white-space: break-spaces;
    }
  }
}