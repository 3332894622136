@import "../mixins/utilities";

.paddingForMobile {
  @media screen and (max-width: 767px) {
    padding-bottom: 170px !important;
  }
}

.KodanshaFooter {
  width: 100%;
  margin: 100px auto 0 auto;
  padding: 0;
  display: flex;
  font-family: Maax-Core, Maax, sans-serif;
  flex-direction: column;
  background-color: black;

  //max-width: 1500px;

  .go-to-top-link {
    width: 100%;
    height: 100px;
    @include flex-column;
    align-items: flex-end;
    justify-content: center;
    background-color: $color-white;

    .go-to-top-link-text {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      border-bottom: 2px solid #757575;
      margin-right: 30px;
      font-size: 15px;
      color: #757575;
      line-height: 16px;
      font-weight: 100;
      font-family: Maax-Medium-Core, Maax-Medium, sans-serif;
      cursor: pointer;

      &:hover {
        color: black;
        border-bottom: 2px solid black;
      }
    }
  }

  .container {
    background-color: black;
    color: white;
    width: 100%;
    max-width: 100%;
    display: flex;
    flex-direction: column;

    .logo-wrapper {
      width: 100%;
      padding-left: 20px;
      height: auto;
      margin: 40px auto 0;
    }

    .wrapper {
      display: flex;
      height: auto;
      flex-direction: row;
      margin-top: 30px;
      padding-bottom: 100px;

      .about-wrapper {
        width: 55%;
        display: flex;
        padding-left: 20px;
        flex-direction: column;


        .footer-title {
          width: 90%;
          font-weight: bolder;
          margin-top: 30px;
          font-size: 40px;
        }

        .about-text {
          width: 90%;
          margin-top: 20px;
          color: #d1d1d1;
        }
      }

      .contact-copyright-wrapper {
        display: flex;
        width: 40%;
        //font-weight: bold;
        margin-left: auto;
        flex-direction: row;
        text-transform: uppercase;
        justify-content: flex-end;
        padding-right: 20px;
        max-width: 500px;

        .column-wrapper {
          display: flex;
          width: 50%;
          flex-direction: column;
          list-style: none;
          margin-top: 0;
          font-size: 15px;
          @include font-medium;

          @media screen and (max-width: 768px) {
            width: 100%;
          }

          li {
            margin-top: 10px;
            padding-left: 15px;
            display: flex;
            justify-content: flex-start;
          }

          :first-child {
            margin-top: 10px;
          }

          li a {
            color: white;
          }
        }

        .second-column-wrapper {
          width: auto;
        }
      }
    }

    .terms-of-use-desktop {
      display: none;
      width: 100%;
      padding-bottom: 100px;
      @include font-medium;
      color: #c1c1c1;
      padding-left: 20px;
    }


    .terms-of-use {
      width: 95%;
      margin: 0px 0 20px 0;
      color: #797979;
      display: flex;
      font-family: Maax-Core, 'Maax', sans-serif;
      padding-left: 20px;
    }

    .powered-by {
      position: relative;
      display: inline-block;
      text-decoration: none;
      width: fit-content;
      margin-left: auto;
      margin-right: 20px;

      svg {
        position: absolute;
        top: -7px;
        right: -5px;
        fill: black;
        display: inline-block;
        width: 25px;
        transform: rotate(-20deg);
        transition: all 0.4s ease-in-out;
        transition-timing-function: ease-out;
      }

      span {
        position: relative;
      }

      &:hover {
        color: white;
        text-decoration: underline;

        svg {
          fill: white;
          top: -14px;
          right: -13px;
          transform: rotate(24deg);
        }

        span {
          background: linear-gradient(to right, #ff1ed1 0%, #58cdff 50%);
          -webkit-background-clip: text;
          background-clip: text;
          -webkit-text-fill-color: transparent;
          text-fill-color: transparent;
          background-size: 500% auto;
          animation: textShine 0.7s ease-in-out;
          animation-fill-mode: forwards;
        }

        @keyframes textShine {
          0% {
            background-position: 0% 50%;
          }
          100% {
            background-position: 100% 50%;
          }
        }
      }

    }

    .do-not-sell-my-data {
      color: white;
      text-transform: uppercase;
      @include font-medium;
      letter-spacing: 1px;
      display: flex;
      width: fit-content;
      margin: auto 0 20px 0;
      @include font-medium;
      padding-left: 20px;

      &:hover {
        color: #c1c1c1;
        cursor: pointer;
      }
    }

    @media only screen and (max-width: 1278px) {
      .wrapper {
        flex-direction: column;

        .about-wrapper {
          width: 100%;
          padding-left: 20px;
        }


        .contact-copyright-wrapper {
          //margin-left: 20px;
          //padding-left: 20px;
          width: 100%;
          flex-direction: column;
          justify-content: flex-start;
          max-width: unset;

          :first-child {
            margin-top: 30px;
          }

          .column-wrapper {
            //margin-left: 5px;
            padding-left: 5px;
          }
        }

        .terms-of-use {
          margin: auto 0 20px 0;
          display: none;
        }
      }

      .terms-of-use-desktop {
        display: flex;
      }
    }

    @media only screen and (min-width: 1500px) {
      .contact-copyright-wrapper {

      }
    }
  }

  .footer-beta {
    display: inline-block;
    position: relative;
    border-radius: 12px;
    //border: 2px solid white;
    height: 22px;
    width: fit-content;
    font-size: 12px;
    font-family: Maax-Medium-Core, 'Maax-Medium', sans-serif;
    margin-left: 23px;
    margin-top: 2px;
    padding: 3px 20px 0px 20px;
    color: black;
    background: #dddddd;
  }


}


.right-column-contact-copyright-wrapper {
  display: grid;
  width: 45%;
  grid-template-columns: 1fr 1fr 1fr;
  margin-top: 40px;
  margin-right: 30px;
  margin-left: auto;
  max-width: 700px;
  grid-column-gap: 40px;

  @media screen and (max-width: 1278px) {
    width: auto;
    margin-left: 20px;
  }

  @media screen and (max-width: 620px) {
    grid-template-columns: 1fr 1fr;
  }

  .right-column-each-column {
    @include flex-column;
    color: white;
    width: fit-content;
    max-width: 300px;


    .right-column-each-column-title {
      font-size: 14px;
      @include font-x-light;
      letter-spacing: 1px;
    }

    .right-column-each-column-divider {
      @include divider(2px);
      background-color: $color-text-secondary;
      margin-top: 5px;
    }

    .right-column-each-column-items-wrapper {
      //margin-top: 10px;
      @include flex-column;

      a {
        text-decoration: none;
        color: $color-white;
        line-height: 17px;
        font-size: 13px;
      }

      > :first-child {
        margin-top: 15px;
      }

      > :not(:first-child) {
        margin-top: 20px;
      }

      .right-column-each-column-items-text {
        letter-spacing: 1px;
        font-size: 13px;
        @include font-x-light;
        line-height: 17px;
        cursor: pointer;
      }
    }
  }

  .right-column-each-column-first {
    margin-left: auto;
    @media screen and (max-width: 1278px) {
      margin-left: 0;
    }
  }

  .right-column-each-column-second {
    margin: 0 auto;
    @media screen and (max-width: 1278px) {
      margin: 0 auto 0 0;
    }
  }

  .right-column-each-column-icons-container {
    display: grid;
    grid-template-columns: 1fr;
    height: fit-content;

    @media screen and (max-width: 620px) {
      margin-top: 40px;
      grid-column: 1 / -1;
      grid-template-columns: 1fr 1fr;
      grid-column-gap: 40px;
    }

    .right-column-each-column-icons {
      @extend .right-column-each-column;
      margin-right: auto;
      height: fit-content;
      //width: 100%;

      .right-column-each-column-icons-wrapper {
        //@include flex-row;
        margin-top: 4px;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
        grid-column-gap: 15px;
        grid-row-gap: 10px;
        //width: fit-content;

        @media screen and (max-width: 500px) {
          grid-template-columns: 1fr 1fr 1fr;
        }

        //> :not(:first-child) {
        //  margin-left: 15px;
        //}

        svg {
          width: 20px;
          height: 20px;
          margin-top: 12px;
          @include flex-column-center;
          cursor: pointer;
        }

        .right-column-instagram-icon {
          width: 22px;
        }

        .right-column-icon-image {
          width: 20px;
          height: 20px;
          margin-top: 13px;
        }

        .right-column-instagram-icon-twitter {
          width: 19px;
          height: 19px;
          margin-top: 12px;
        }

        .right-column-icon-instagram {
          width: 17px;
          height: 17px;
          margin-top: 13px;
        }

        .right-column-icon-image-facebook {
          width: 19px;
          height: 19px;
          margin-top: 12px;
        }

        .right-column-icon-image-tumblr {
          width: 16px;
          height: 16px;
          margin-top: 13px;
        }

        .right-column-icon-youtube {
          height: 22px;
          width: 22px;
          margin-top: 10px;
        }
      }
    }

    .right-column-each-column-icons-second {
      @extend .right-column-each-column-icons;
      margin-top: 40px;
      @include flex-column;

      @media screen and (max-width: 620px) {
        margin: 0 auto 0 0;
      }

      @media screen and (min-width: 1278px) {
        margin-top: 40px;
      }

      .right-column-each-column-icons-wrapper-second {
        @extend .right-column-each-column-icons-wrapper;
        grid-template-columns: 1fr 1fr 1fr;

        @media screen and (min-width: 620px) {
          grid-template-columns: 1fr 1fr 1fr 1fr;
        }
      }
    }
  }

}





