@import '../mixins/utilities.scss';

.ax-benefit-container {
  @include flex-column;
  margin-top: 60px;

  .first-header {
    font-size: 45px;
    @include font-bold;
    letter-spacing: -1px;
    line-height: 45px;
  }

  .second-header {
    font-size: 30px;
    @include font-bold;
    letter-spacing: -1px;
  }

  .benefits-grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    margin-top: 30px;
    grid-column-gap: 20px;

    @media screen and (max-width: 1024px) {
      @include flex-column;

      > :not(:first-child) {
        margin-top: 35px;
      }
    }

    .benefit-grid-item-wrapper {
      @include flex-column;

      .benefit-grid-header-wrapper {
        display: grid;
        grid-template-columns: 50px auto;
        grid-column-gap: 20px;

        .ax-benefit-icon {
          width: 50px;
          height: 50px;
        }

        .benefit-item-header {
          @include font-medium;
          font-size: 18px;
          line-height: 18px;

          @media screen and (max-width: 1024px) {
            font-size: 28px;
            margin-top: 7px;
            line-height: 25px;
          }
        }
      }

      .benefit-grid-list {
        margin-top: 10px;
        @include font-x-light;
        white-space: break-spaces;
        padding-left: 30px;
        font-size: 16px;

        @media screen and (max-width: 1024px) {
          padding-left: 20px;
          margin-top: 15px;
          font-size: 18px;
        }
      }
    }

  }
}