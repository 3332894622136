@import '../mixins/utilities.scss';

.discount-mal-container {
  width: 100%;
  height: auto;
  margin-top: 100px;
  align-items: center;
  display: flex;
  flex-direction: column;

  .header-mal {
    font-size: 45px;
    letter-spacing: -1px;
    @include font-bold;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .code-mal {
    @include border-b(3px);
    width: 600px;
    height: 150px;
    padding: 20px 10px;
    border-radius: 100px;
    font-size: 50px;
    @include font-bold;
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;

    @media screen and (max-width: 768px) {
      width: 95%;
      height: 120px;
    }

    @media screen and (max-width: 420px) {
      width: 95%;
      height: 100px;
      font-size: 40px;
    }
  }

  .explain-mal {
    margin-top: 50px;
    font-size: 20px;
    max-width: 680px;
    line-height: 22px;
    @include font-medium;

    .explain-mal-twitter {
      text-underline-offset: 5px;
    }
  }

  .list-wrapper-mal {
    font-size: 20px;
    max-width: 600px;
    @include font-medium;
    line-height: 22px;
    padding-left: 25px;
  }
}