@import '../mixins/utilities.scss';

.ax-end-container {
  @include flex-column;
  width: 65%;
  margin: 60px auto 0 auto;

  @media screen and (max-width: 1024px) {
    width: 100%;
  }

  .ax-end-more-wrapper {
    display: grid;
    grid-template-columns: 230px auto;

    @media screen and (max-width: 1024px) {
      @include flex-column;
    }
  }

  .ax-end-more-header {
    @include flex-column-center;
    @include font-bold;
    font-size: 35px;

    @media screen and (max-width: 1024px) {
      align-items: flex-start
    }
  }

  .ax-end-more-text {
    font-size: 18px;
    @include font-x-light;
  }

  .ax-create-account-btn {
    margin: 50px auto 0 auto;
    width: fit-content;
    letter-spacing: 1px;
    font-size: 20px;
    padding: 20px 100px 15px 100px;

    @media screen and (max-width: 1024px) {
      width: 100%;
      padding: 20px 0 15px 0;
      margin: 0 auto 0 auto;
      font-size: 17px;
    }
  }

  .ax-digital-manga-notice {
    color: $color-text-secondary;
    font-size: 15px;
    margin: 70px auto 0 auto;
    @include font-medium;

    @media screen and (max-width: 1024px) {
      text-align: center;
      width: 85%;
      margin: 15px auto 0 auto;
    }
  }
}