@import '../mixins/utilities.scss';


.series-ax-container {
  margin-top: 50px;
  @include flex-column;
  padding-bottom: 30px;

  .header-one {
    font-size: 20px;
    letter-spacing: 1px;
    @include font-medium;
  }

  .header-two {
    @include font-bold;
    font-size: 40px;
    line-height: 43px;
  }

  .until {
    font-size: 25px;
    @include font-bold;
  }

  .series-mal-grid-wrapper {
    display: flex;
    width: 100%;
    flex-direction: row;
    margin-top: 20px;

    .swiper {
      width: 100%;
      height: 100%;
    }

    .swiper-slide {
      text-align: center;
      font-size: 18px;
      background: #fff;
      height: fit-content;

      display: -webkit-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-justify-content: flex-start;
      justify-content: flex-start;
      -webkit-align-items: flex-start;
      align-items: flex-start;
    }

    .swiper-wrapper {
      display: flex;
      width: fit-content;
    }
  }

  .link-view-more-mal {
    width: fit-content;
    align-self: flex-end;
    margin-top: 50px;
    @include router_link;

    .arrow-container {
      align-self: flex-end;
    }
  }
}

.series-item-wrapper {
  @include flex-column;
  background-color: white;
  width: 100%;

  .ui-product-poster-container {
    background-color: #dddddd;
  }

  .series-item-link {
    @include router_link;
    @include flex-column;
    width: 100%;
    height: auto;
  }

  .series-item-info-wrapper {
    width: 90%;
    @include flex-column;
    padding-bottom: 15px;
    margin: 10px auto;

    a {
      @include router_link;
      margin-top: 20px;
      align-self: flex-start;
    }
  }

  .series-name {
    font-size: 24px;
    @include font-medium;
    margin-top: 10px;
    margin-bottom: 0;
    white-space: break-spaces;
    display: flex;
    text-align: start;
    justify-content: flex-start;
  }

  .sx-author-name {
    color: $color-text-secondary;
    margin-top: 0;
    display: flex;
    justify-content: flex-start;
  }

  .ui-underlined-text {
    margin-top: 20px;
    align-self: flex-start;
    font-size: 14px;
    border-bottom-color: black;
    line-height: 15px;
  }

  .genres-wrapper {
    @include flex-row;
    flex-wrap: wrap;
    column-gap: 5px;
    line-height: 15px;
    margin-top: 10px;

    //> :not(:first-child) {
    //  margin-left: 5px;
    //}

    span {
      color: $color-text-secondary;
      @include font-medium;
      font-size: 15px;
      display: flex;
      //white-space: nowrap;
      white-space: break-spaces;
    }
  }
}