@import "../../mixins/utilities";

.kodansha-email-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;

  .email-input {
    width: 100%;
    height: 40px;
    border: 2px solid #000000;
    padding: 0 10px;
    outline: none;
    border-radius: 0;
    -webkit-appearance: none;
  }

  .error {
    color: $color-red;
    width: 100%;
    display: flex;
    text-align: start;
  }
}
