.ReaderPage {
    transition-duration: 0ms;
    background-color: transparent;
    flex-direction: 'column';
    flex-flow: 'column';
    background-color: rgba(0, 0, 0, 0);
}

.ReaderPage div {
    transition-duration: 200ms;
    background-color: rgba(0, 0, 0, 0);
}
