@import "../../mixins/utilities";

.ui-series-poster-container {
  @include flex-column-center;
  width: 100%;
  height: auto;
  // convert from 100% to auto, to fix a bug in news.
  background-color: $color-f1;
  position: relative;
  //padding-top: 150%;
  user-select: none;
  transition: opacity 125ms ease-out, background-color 125ms ease-out;

  .on-sale {
    position: absolute;
    top: 0;
    left: 0;
    @include font-medium;
    color: $color-white;
    background-color: $color-black;

    width: fit-content;
    padding: 6px 7px 0 7px;
    height: 27px;
    font-size: 12px;
    font-weight: 100;
    font-family: Maax-Medium-Core, Maax-Medium, sans-serif;
    z-index: 1;
    letter-spacing: 1px;
  }

  .on-sale-product-page {
    top: 11.5%;
    left: 15%;
    font-size: 16px;
    padding: 8px 10px 0 10px;
    height: 35px;

    @media screen and (max-width: 950px) {
      top: 0;
      left: 0;
      height: 27px;
      font-size: 12px;
      padding: 6px 7px 0 7px;
    }
  }


  .ui-series-poster-container-2 {
    @include flex-column-center;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;


    .ui-series-poster-wrapper {
      width: 100%;
      height: 100%;
      background-color: $color-c6;
      //box-shadow: rgba(0, 0, 0, 0.1) 0 4px 12px;
      overflow: hidden;

      .ui-series-poster-image {
        //width: auto;
        width: 100%;
        max-width: 100%;
        filter: grayscale(100%);
        height: 100%;
        pointer-events: none;
        user-select: none;
        object-fit: cover;
        @include flex-column-center;

      }
    }
  }
}

.ui-series-poster-container.ui-series-poster-container-hover {
  .colorize {
    cursor: pointer;

    &:hover {
      opacity: 0.6;
    }
  }
}