

.ImagePlaceholder {

  background-color: white;
  width: 70%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  aspect-ratio: 0.67;
  box-shadow: rgba(0, 0, 0, 0.1) 0 4px 12px;

  img {
    height: auto;
    width: 50%;
  }

}
