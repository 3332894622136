@import "./mixins/utilities";

//Load the minimal core fonts containing latin glyphs that should work almost all the time:

@font-face {
  font-family: 'Maax-Core';
  font-style: normal;
  font-display: swap;
  src:
          url('KodanshaAssets/fonts/maax-regular.latin.woff2') format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: 'Maax-Medium-Core';
  font-style: normal;
  font-display: swap;
  src:
          url('KodanshaAssets/fonts/maax-medium.latin.woff2') format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: 'Maax-Bold-Core';
  font-style: normal;
  font-display: swap;
  src:
          url('KodanshaAssets/fonts/maax-bold.latin.woff2') format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: 'GBC-Core';
  font-style: normal;
  font-display: swap;
  src:
          url('KodanshaAssets/fonts/galaxiecopernicus-book.latin.woff2') format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

//Then load the rest of the fonts

@font-face{
  font-family: 'GBC';
  src: url("KodanshaAssets/fonts/galaxiecopernicus-book.woff2") format("woff2");
  font-display: swap;
}

@font-face{
  font-family: 'Maax';
  src: url("KodanshaAssets/fonts/maax-regular.woff2") format("woff2");
  font-display: swap;
}

@font-face{
  font-family: 'Maax-Medium';
  src: url("KodanshaAssets/fonts/maax-medium.woff2") format("woff2");
  font-display: swap;
}

@font-face {
  font-family: 'Maax-Bold';
  src: url("KodanshaAssets/fonts/maax-bold.woff2") format("woff2");
  font-display: swap;
}

.no-scroll {
  overflow: hidden;
}

.scroll {
  overflow: unset;
}

body {
  font-family: Maax-Core, Maax, Verdana;
  color: #000000;
  font-size: 16px;
  font-weight: $font-weight-base;
  line-height: $line-height-base;
}

// maybe change to 767px again
$mobile-width: 1024px;
$container-width: 960px;

.show-on-mobile {
  display: none;
}

.hide-on-mobile {
  display: inherit !important;
}

.container {
  margin: 0 auto;
  max-width: $container-width;
}

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.col, .col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col-auto, .col-lg, .col-lg-1, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-auto, .col-md, .col-md-1, .col-md-10, .col-md-11, .col-md-12, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-auto, .col-sm, .col-sm-1, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-auto, .col-xl, .col-xl-1, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

// NB: The following work due to the order in which the rules apply once media queries are satisfied.

@media (min-width: 576px) {
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
}

@media (min-width: 768px) {
  .col-md-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
}

@media (min-width: 992px){
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
}

// Styling for mobile devices
@media (max-width: $mobile-width){
  .show-on-mobile {
    display: inherit;
  }

  .hide-on-mobile {
    display: none !important;
  }
}

.stop-scrolling {
  height: 100%;
  overflow: hidden;
}

.overlay-full {
  background-color: white;
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 200;
}

//Common styling across site

.colorize {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  mix-blend-mode: multiply;
  opacity: 0.4;
  transition: opacity 125ms ease-out, background-color 125ms ease-out;
}
