@import "../mixins/utilities";

.InkyPenButton {
  background-color: white;
  display: flex;
  width: auto;
  height: auto;
  border: 2px solid black;
  @include font-bold;

  .tilegroup {
    height: 100%;
    display: flex;
    flex-wrap: wrap;

    .tile-1 {
      flex: 0 0 33.333333%;
      height: 10px;
      background-color: black;
    }

    .tile-2 {
      flex: 0 0 33.333333%;
      height: 10px;
      background-color: black;
    }

    .tile-3 {
      flex: 0 0 33.333333%;
      height: 10px;
      background-color: black;
    }

    .tile-4 {
      flex: 0 0 33.333333%;
      width: 10px;
      height: 100%;
      background-color: black;
    }

    .tile-5 {
      flex: 0 0 33.333333%;

    }

    .tile-6 {
      flex: 0 0 33.333333%;
    }

    .tile-7 {
      height: 10px;
      background-color: black;
    }

  }

  .border-start {
    width: 1%;
    background-color: black;
  }

  .label {
    display: flex;
    width: auto;
    justify-content: center;
    align-items: center;
    height: auto;
    border-right: 2px solid black;
    line-height: 100%;
    color: black;
    text-align: center;
    text-transform: uppercase;
    padding: 4px 20px 0;

    //&.has-icon {
    //  border-right: 0;
    //}
  }

  img {
    width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    fill: red;
    background-color: white;
    height: auto;
    transition: transform 1s linear;
  }

  &.no-text {
    aspect-ratio: 1 / 1;
    background-color: white;
  }

  .is-reverse {
    border: 2px solid $color-black;
    border-left: none;
  }

  .not-reverse {
    border: 2px solid $color-black;
    border-right: none;
  }


  &.reader-navigation {
    background-color: transparent;
    border: none;
    fill: white;

    img {
      filter: invert(1);
      background-color: transparent;
    }

  }

}

.inkypenButton:hover {
  cursor: pointer;
  background-color: black;

  .label {
    color: white;
    border-right: 2px solid white;
  }

  img {
    filter: invert(1);
    //transform: translateX(-30px);
  }

  &.reader-navigation {
    background-color: transparent;
    border: none;
  }
}
