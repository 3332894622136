@import "src/Kodansha/mixins/utilities";

.arrow-container {
  display: flex;
  flex-direction: row;
  width: auto;
  color: black;
  //justify-content: center;
  //align-items: center;
  border: 2px solid black;
  height: 35px;
  overflow: hidden;
  min-height: 35px;
  cursor: pointer;

  .arrow-btn {
    width: 95%;
    height: auto;
    font-weight: bold;
    text-transform: uppercase;
    display: flex;
    justify-content: center;
    border-right: 2px solid black;
    font-size: 11px;
    padding: 4px 10px 0 10px;
    letter-spacing: 2px;
    align-items: center;
    font-family: Maax-Core, Maax, serif;
  }

  .with-arrow.disabled {
    border-right: 2px solid #757575
  }
  .no-arrow {
    width: 100%;
    border-right: none;
    padding: 3px 10px 0 10px;
  }

  .icon-wrapper {
    display: flex;
    height: 100%;
    width: auto;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    border: none;
    flex-shrink: 0;
    flex-grow: 1;



    //@media only screen and (-webkit-min-device-pixel-ratio: 1) {
      //max-width: 30px;
      //color: #0000FF;
      //background-color: #CCCCCC;
    //}

    .icon {
      filter: invert(1);
      width: auto;
      transform: scaleY(1.05);
      height: 100%;
    }

    @keyframes move {
      from {
        transform: translateX(-40px);
      }
      to {
        transform: translateX(0px);
      }
    }
  }


  .text-bold {
    font-size: 14px;
    @include font-medium;
    //letter-spacing: 0;
    letter-spacing: 1px;
  }

}

.arrow-container.disabled {
  border: 2px solid #757575;
  cursor: default;
  .arrow-btn {
    background-color: #F1F1F1;
    //border-right: none;
    border-color: #757575;
    color: #ABABAB;
  }
  .icon-wrapper {
    background-color: #F1F1F1;
  }
}

.arrow-container.invert {
  filter: invert(1)
}

.arrow-container:not(.disabled):hover {
  color: white;
  background-color: black;

  .arrow-btn {
    border: none;
    border-right: 2px solid white;
  }

  .no-arrow {
    border-right: none;
  }

  .icon {
    filter: invert(0);
    animation: move 300ms;
  }
}

.sign-up-to-read-button-style {
  color: $color-white;
  background-color: $color-black;
}

.not-for-sale-button-style {
  color: $color-white;
  background-color: $color-black;

  cursor: default;
  user-select: none;

  &:hover {
    cursor: default;
  }
}

.read-button-style {
  color: $color-white;
  background-color: $color-black;
}