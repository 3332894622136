@import "../../mixins/utilities";

.ui-product-poster-container {
  @include flex-column-center;
  width: 100%;
  height: auto;
  background-color: $color-f1;
  position: relative;
  transition: background-color 0.3s;

  .on-sale {
    position: absolute;
    top: 0;
    left: 0;
    @include font-medium;
    color: $color-white;
    background-color: $color-black;

    width: fit-content;
    padding: 6px 7px 0 7px;
    height: 27px;
    font-size: 12px;
    font-weight: 100;
    font-family: Maax-Medium-Core, Maax-Medium, sans-serif;
    z-index: 1;
    letter-spacing: 1px;
  }

  .on-sale-product-page {
    top: 11.5%;
    left: 15%;
    font-size: 16px;
    padding: 8px 10px 0 10px;
    height: 35px;

    @media screen and (max-width: 950px) {
      top: 0;
      left: 0;
      height: 27px;
      font-size: 12px;
      padding: 6px 7px 0 7px;
    }
  }

  .ui-product-poster-container-2 {
    @include flex-column-center;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;

    .ui-product-poster-wrapper {
      width: 100%;
      height: 100%;
      overflow: hidden;
      @include flex-column-center;

      picture {
        height: 100%;
        width: 100%;
        @include flex-column-center;
      }

      .ui-product-poster-image {
        width: auto;
        object-fit: contain;
        height: 75%;
        box-shadow: rgba(0, 0, 0, 0.1) 0 4px 12px;
      }

      .ui-product-poster-image-2 {
        @extend .ui-product-poster-image;
        max-width: 70%;
      }
    }
  }
}

.ui-product-poster-container-hover:hover {
  cursor: pointer;
  background-color: $color-gray-mid;
}
