@import '../mixins/utilities.scss';

.six-ax-container {
  margin-top: 50px;
  @include flex-column;

  .six-ax-wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    margin-top: 20px;
    grid-column-gap: 20px;
    grid-row-gap: 35px;


    @media screen and (max-width: 1024px) {
      grid-template-columns: 1fr 1fr 1fr;
    }


    @media screen and (max-width: 768px) {
      grid-template-columns: 1fr 1fr;
    }
  }

  .header-one {
    font-size: 20px;
    letter-spacing: 1px;
    @include font-medium;
  }

  .header-two {
    @include font-bold;
    font-size: 40px;
    line-height: 43px;
  }
}

.six-item-wrapper {
  @include flex-column;

  .ui-product-poster-container-hover:hover{
    cursor: inherit;
    background-color: #F1F1F1;
  }

  a{
    text-decoration: none;
    color: black;
    &:hover{
      text-decoration: none;
      color:black;
    }
  }

  .sampler {
    @include font-medium;
    letter-spacing: 1px;
    color: $color-text-secondary;
    margin-top: 10px;
    font-size: 15px;
  }

  .name {
    @include font-medium;
    line-height: 25px;
    font-size: 25px;
    margin-top: 5px;
  }

  .ui-underlined-text {
    align-self: flex-start;
    margin-top: 30px;
    @include font-medium;
    font-size: 15px;
    border-bottom: 2px solid black;
    line-height: 15px;
  }
}