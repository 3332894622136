@import '../mixins/utilities.scss';

.ax-booth-container {
  display: grid;
  width: 100%;
  grid-template-columns: 5fr 5fr;
  margin-top: 60px;
  grid-column-gap: 30px;

  @media screen and (max-width: 1024px) {
    @include flex-column;
  }


  .booth-image-wrapper {
    width: 100%;
    @include flex-column;

    .booth-image {
      width: 100%;
      height: auto;
    }
  }

  .text-info-wrapper {
    @include flex-column;

    .first-header {
      font-size: 55px;
      line-height: 60px;
      letter-spacing: -1px;
      @include font-bold;

      @media screen and (max-width: 1536px) {
        font-size: 45px;
        line-height: 50px;
      }
    }

    .third-header {
      @include font-medium;
      letter-spacing: 1px;
      font-size: 20px;

      @media screen and (max-width: 1024px) {
        margin-top: 30px;
      }
    }

    .check-show {
      @include font-x-light;
      font-size: 19px;
      margin-top: 30px;
    }


    .show-list {
      margin-top: 20px;
      @include font-x-light;
      font-size: 18px;
      padding-left: 30px;

      .show-list-item {
        line-height: 24px;
      }
    }

    .link-to-details {
      margin-top: auto;
      align-self: flex-start;
      @include router_link;

      .arrow-container {
        height: 40px;
      }

      @media screen and (max-width: 1024px) {
        align-self: flex-end;
      }
    }
  }
}