@import './mixins/utilities.scss';

.anime-expo-container {
  @include flex-column;

  @media (max-width: 1024px) {
    .AXHero .ui-series-poster-container {
      margin-top: 80px;
    }
  }

  .anime-expo-color-gap {
    margin-top: 30px;

    @media screen and (max-width: 768px) {
      margin-top: 0;
    }
  }

  .anime-expo-color {
    width: 100%;
    background-color: $color-gray-light;
    margin-top: 70px;
  }

  .mal-color {
    width: 100%;
    background-color: #f8f8f8;
    margin-top: 70px;
  }

  .anime-expo-max-wrapper {
    @include flex-column;
    width: 90%;
    max-width: 1440px;
    margin: 0 auto;
  }
}
