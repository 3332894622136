@import "../mixins/utilities";

.KodanshaCart {
  min-height: 200px;
  display: flex;
  justify-content: flex-end;
  max-height: 85vh;

  .upsell-section {
    //flex-grow: 7;
    width: 60%;
    flex-grow: 1;
    @include flex-column-center;
    border-right: 2px solid black;
    background-color: $color-f1;
    flex-shrink: 0;

    @media screen and (min-width: 767px) and (max-width: 1024px) {
      width: 45%;
      max-width: unset;
    }
  }

  .cart-section {
    display: flex;
    flex-direction: column;
    //flex-grow: 3;
    width: 40%;
    @include border-b(2px);
    border-top: none;
    justify-content: flex-start;
    max-width: 550px;
    overflow-y: auto;
    @include tiny-scroll;
    background-color: $color-white;

    @media screen and (max-width: 1024px) {
      width: 100%;
      max-width: unset;
    }

    .cart-order-title {
      @include font-medium;
      width: 100%;
      padding-left: 14px;
      margin: 30px auto 0;
      font-size: 19pt;
    }

    h3 {
      text-align: center;
    }

    .total-cart {
      border-top: 2px solid black;
      display: flex;
      flex-direction: column;
      padding: 20px 70px;
      width: 100%;

      @media screen and (min-width: 768px) and (max-width: 1300px) {
        padding: 20px 30px;
      }

      @media screen and (min-width: 768px) and (max-width: 1100px) {
        padding: 20px 20px;
      }

      @media screen and (max-width: 550px) {
        padding: 20px 20px;
      }

      .subtotal {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        font-size: 24pt;
        font-weight: bold;

        @media screen and (min-width: 768px) and (max-width: 900px) {
          font-size: 18pt;
        }

        @media screen and (max-width: 450px) {
          font-size: 20pt;
        }

        @media screen and (max-width: 400px) {
          font-size: 15pt;
        }
      }

      .disclaimer {
        display: flex;
        justify-content: center;
        margin-bottom: 10px;
      }

      .cart-tax {
        @include font-medium;
        font-size: 12pt;
        display: flex;
        margin-top: 7px;
        text-transform: uppercase;
        justify-content: flex-start;

        @media screen and (min-width: 768px) and (max-width: 900px) {
          font-size: 10pt;
        }

        @media screen and (max-width: 450px) {
          font-size: 9pt;
        }

        @media screen and (max-width: 360px) {
          font-size: 8pt;
        }

      }
    }

    .cart-checkout {
      height: 25px;
      flex-grow: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: black;
      font-size: 24pt;
      padding: 28px 0 20px 0;
      flex-direction: row;
      @include font-bold;
      cursor: pointer;
      min-height: 75px;

      @media screen and (max-width: 500px) {
        font-size: 20pt;
        //min-height: unset;
        @include font-medium;
      }

      a:link, a:visited {
        //flex: 1;
        //font-weight: bold;
        //@include font-bold;
        color: white;
        display: block;
        text-align: center;
      }

      svg {
        margin-left: 20px;
        width: 40px;

        @media screen and (max-width: 500px) {
          width: 30px;
        }
      }
    }

    .cart-cta {
      height: 25px;
      flex-grow: 1;
      border-top: 4px solid black;
      display: flex;
      justify-content: center;
      font-size: 19pt;
      padding: 28px 0 20px 0;
      @include font-medium;
      align-items: center;
      min-height: 75px;
      cursor: pointer;

      @media screen and (max-width: 500px) {
        font-size: 15pt;
        @include font-bold;
        //min-height: unset;
      }

      button {
        background-color: transparent;
        outline: none;
        border: none;
        color: black;
      }

      button:link, a:visited {
        flex: 1;
        font-weight: bold;
        color: black;
        display: block;
        text-align: center;
      }
    }

  }

}


.cart-headers {
  display: grid;
  grid-template-columns: 80px 5fr 3fr 2fr 1fr;
  width: 100%;
  height: auto;
  margin: 10px auto 0;
  border-bottom: 2px solid $color-black;
  padding-bottom: 10px;
  //overflow-y: scroll;
  //
  //scroll-behavior: smooth;
  //scrollbar-color: white transparent;
  //scrollbar-width: thin;

  .cart-header {
    @include flex-column;
    align-self: flex-start;
    text-align: center;
    font-size: 12pt;
    @include font-x-light;
    color: $color-text-secondary;
  }

  .cart-header-one {
    @extend .cart-header;
    flex-grow: 3;
    padding-left: 10px;
  }

  .cart-header-two {
    @extend .cart-header;
    flex-grow: 2;
    text-align: start;
  }

  .cart-header-three {
    @extend .cart-header;
    flex-grow: 2;
    text-align: center;
    padding-right: 11% !important;
  }
}

.cart-headers-success {
  grid-template-columns: 80px 5fr 3fr 2fr;
  border-bottom: none;
}

.cart-items-content {
  background-color: white;
  flex: 10;
  overflow: auto;
  max-height: 40vh;
  overflow-x: hidden;

  scroll-behavior: smooth;
  scrollbar-color: $color-black transparent;
  scrollbar-width: thin;

  .cart-item-first {
    margin-top: 10px;
  }

  .cart-item {
    border-bottom: 1px solid black;
    justify-content: left;
    display: grid;
    grid-template-columns: 80px 5fr 3fr 2fr 1fr;
    @include font-medium;

    a, a:visited,
    a:active {
      color: inherit;
      text-decoration: none;
    }

    .button-no-style {
      all: initial;
      padding-bottom: 5px;
      &:hover {
        cursor: pointer;
      }
    }

    .button-no-style-no-hover {
      cursor: default !important;
    }

    .cart-item-cancel-icon {
      transform: rotate(45deg);
    }

    .thumbnail {
      padding: 10px;
      width: 80px;
      position: relative;

      .is-on-sale-tag {
        position: absolute;
        top: 5px;
        background-color: $color-black;
        color: $color-white;
        padding: 5px 7px;
        font-size: 10px;
      }

      img {
        width: 100%;
        object-fit: cover;
        height: auto;
      }
    }

    .metadata {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 100%;

      .distribution-type {
        text-transform: uppercase;
        color: $color-text-secondary;
      }

      h3 {
        text-align: left;
        font-size: 1.2em;
        @include nth-line-text(2);
        font-weight:500;
      }
    }

    .amount-cart {
      @include flex-column;
      align-items: center;
      width: 100%;
      text-align: start;
      justify-content: flex-start;
      display: flex;
      flex-direction: row;
      //max-width: 100px;
      padding-left: 35px;

      svg {
        @media screen and (max-width: 576px) {
          width: 13px;
          height: 13px;
        }
      }

      button {
        padding-left: 10px;
        padding-right: 10px;
      }

      .quantity {
        //margin: 0 auto;
      }
    }

    .amount-cart-physical {
      padding-left: 0;
    }

    .price {
      margin: auto;
      width: 100%;
      text-align: center;
      span{
        color: grey;
        text-decoration: line-through
      }
    }

    .actions {
      margin: auto;
      width: 100%;
      text-align: center;

      a:link, a:visited {
        color: black;
        font-weight: bold;
      }

    }
  }

  .cart-item-success {
    grid-template-columns: 80px 5fr 3fr 2fr;
    border-bottom: none;
  }
}

.no-items-in-cart {
  margin: auto 0;
  height: 150px;
  @include flex-column-center;
  padding: 15px 0 10px 0;
  @include font-bold;
}