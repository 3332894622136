@import '../mixins/utilities.scss';


.exclusives-ax-container {
  display: grid;
  width: 100%;
  grid-template-columns: 5fr 5fr;
  margin-top: 60px;
  grid-column-gap: 30px;

  @media screen and (max-width: 1024px) {
    display: flex;
    flex-direction: column-reverse;
  }

  .text-info-wrapper {
    @include flex-column;

    .intro-header {
      font-size: 20px;
      @include font-medium;
      letter-spacing: 1px;
      text-transform: uppercase;

      @media screen and (max-width: 1024px) {
        margin-top: 30px;
      }
    }

    .intro-second-header {
      font-size: 55px;
      line-height: 60px;
      letter-spacing: -1px;
      @include font-bold;

      @media screen and (max-width: 1536px) {
        font-size: 45px;
        line-height: 50px;
      }
    }

    .intro-text-mal {
      font-size: 23px;
      line-height: 30px;
      margin-top: 10px;
      @include font-x-light;

      strong {
        @include font-bold;
      }
    }

    .intro-text-mal-login {
      @extend .intro-text-mal;
      margin-top: 30px;

      u {
        cursor: pointer;
      }
    }

    .intro-text-mal-list {
      @extend .intro-text-mal;
      margin-top: 30px;
    }

    .mal-list {
      font-size: 22px;
      line-height: 30px;
      padding-left: 30px;

      span {
        text-decoration: underline;
        cursor: pointer;
      }

      .ex-list-item-italic {
        //font-style: italic;
      }
    }

    .mal-list-finished {
      @extend .mal-list;
      margin-top: 30px;
    }

    .intro-booth {
      margin-top: 30px;
      @include font-bold;
      font-size: 23px;
      line-height: 23px;
    }

    .intro-booth-mal-top {
      margin-top: 20px;
    }

    .explain-wrapper {
      margin-top: 30px;
      @include flex-column;

      .intro-explain {
        font-size: 15px;
      }
    }
  }

  .gift-wrapper {
    width: 100%;

    img {
      width: 100%;
      height: auto;
    }
  }
}

.mal-news-container {
  @media screen and (max-width: 1024px) {
    display: flex;
    flex-direction: column;

    .gift-wrapper {
      margin-top: 40px;
    }
  }

  .link-to-more-mal-news {
    margin-top: auto;
    width: fit-content;
    @include router_link;

    .arrow-container {
      width: fit-content;

      @media screen and (max-width: 1024px) {
        margin-top: 40px;
        display: none;
      }
    }
  }

}